<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 mb-1">
        <p-icon name="bi-exclamation-triangle-fill" color="warning" />
          This tool helps you to <b class="text-success">merge</b> multiple MSKU and FNSKU pairs into single record.
          Once you merge the selected MSKU and FNSKU pairs, the corresponding inventory records will also merge into
          a single record and the new record's quantity will be the sum of the merging inventory quantities.
          Please select the record you want to keep by clicking on the <b class="text-primary">select</b> button.
          Then, click on the <b class="text-primary">merge</b> switch in the merge column
          for the records you want to merge. Please note that, the selected record will be kept and the
          ones marked as <b class="text-primary">MERGE</b> will be deleted. Please also note that <b class="text-primary">Include Empty</b>
          check indicates that any record without MSKU and FNSKU will also be considered as <b class="text-primary">MERGE</b>
          and will be assigned the selected MSKU and FNSKU. If no MSKU and FNSKU pair associated with a product is found,
          you can still add new MSKU and FNSKU codes and assign them to all existing records.
      </div>
      <div class="col-12 px-0 mb-1">
        <div class="d-flex flex-row row">
          <div class="col-5">
            Selected MSKU
          </div>
          <div class="col-4">
            Selected FNSKU
          </div>
          <div class="col-2">
            Include Empty
          </div>
          <div class="col-1" />
        </div>
        <div class="d-flex flex-row align-items-center row">
          <div class="col-5">
            <dx-util-text-box
              id="selectedMskuTextBox"
              v-model="selectedMsku"
            />
          </div>
          <div class="col-4">
            <dx-util-text-box
              id="selectedMskuTextBox"
              v-model="selectedFnsku"
            />
          </div>
          <div class="col-2">
            <dx-util-check-box
              v-model="includeNulls"
              :disabled="includeEmptyDisabled"
            />
          </div>
          <div class="col-1 d-flex justify-content-end">
            <dx-util-button
              id="resetBtn"
              icon="pulldown"
              hint="Reset Values"
              type="danger"
              @click="onResetValues()"
            />
          </div>
        </div>
      </div>
      <div class="col-12 px-0 mb-2">
        <dx-data-grid
          id="productMergeGrid"
          ref="productMergeGridRef"
          key-expr="id"
          :data-source="dataSource"
          :allow-column-reordering="false"
          :allow-column-resizing="false"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          :no-data-text="gridDataLoadingStatus"
          @content-ready="onContentReady"
        >
          <dx-column data-field="isSelected" caption="" width="auto" cell-template="selected-button" />
          <template #selected-button="{ data }">
            <dx-util-button
              id="mergeBtn"
              width="100px"
              :text="selectButtonOptions(data.value).text"
              :type="selectButtonOptions(data.value).type"
              @click="onSelectRow($event,data)"
            />
          </template>
          <dx-column data-field="productId" caption="Product #" width="auto" alignment="left" />
          <dx-column data-field="msku" caption="MSKU" />
          <dx-column data-field="fnsku" caption="FNSKU" width="auto" />
          <dx-column data-field="merge" caption="Merge" width="auto" cell-template="merge-with-selected" />
          <template #merge-with-selected="{ data }">
            <dx-switch
              v-model="data.value"
              :disabled="data.data.isSelected"
              switched-off-text="NO"
              switched-on-text="MERGE"
              width="80px"
              @value-changed="onMergeSelected($event,data)"
            />
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12 fixed-bottom mb-1 px-2">
        <dx-util-button
          id="saveBtn"
          width="115px"
          text="Save"
          type="success"
          @click="handleSubmitSave()"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import productsService from '@/http/requests/product/productsService'
import inventoryService from '@/http/requests/inventory/inventoryService'
import { Notify } from '@robustshell/utils/index'
import { DxSwitch } from 'devextreme-vue/switch'
import find from 'lodash/find'
import pull from 'lodash/pull'

export default {
  emits: ['merge-product-msku-fnksu'],
  components: {
    DxSwitch,
  },
  mixins: [GridBase],
  props: {
    productId: {
      type: Number,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataSource: [],
      selectedMskuFnskuId: null,
      selectedMsku: '',
      selectedFnsku: '',
      gridDataLoadingStatus: 'Please wait! Data is loading...',
      includeNulls: true,
      includeEmptyDisabled: false,
      ignoredIds: [],
    }
  },
  computed: {
    dataGrid() {
      const dataGrid = this.$refs.productMergeGridRef.instance
      return dataGrid
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal !== 0) {
          this.getProductCodes()
        }
      },
    },
  },
  mounted() {
    this.getProductCodes()
  },
  methods: {
    async getProductCodes() {
      const productId = this.productId
      this.dataSource.splice(0, this.dataSource.length)
      const result = await productsService.fetchCodesById(productId)
      result.data.forEach(item => {
        this.dataSource.push({
          id: item.id,
          msku: item.msku,
          fnsku: item.fnsku,
          productId: item.productId,
          merge: false,
          isSelected: false,
        })
      })
      if (result.data.length === 0) {
        this.gridDataLoadingStatus = 'No MSKU/FNSKU found for this product.'
        this.includeEmptyDisabled = true
      }
    },
    selectButtonOptions(val) {
      return {
        text: val ? 'Selected' : 'Select',
        type: val ? 'danger' : 'default',
      }
    },
    async onSelectRow(e, row) {
      const selectedRow = this.dataGrid.getSelectedRowKeys()
      const dataSource = this.dataGrid.getDataSource()
      const store = dataSource.store()
      const data = { ...row.data }
      if (selectedRow.length === 0) {
        this.selectedMskuFnskuId = data.id
        this.selectedMsku = data.msku
        this.selectedFnsku = data.fnsku

        this.dataGrid.selectRows([data.id], true)
        data.isSelected = true
        data.merge = false
        store.update(data.id, data)
      } else if (selectedRow[0] === data.id) {
        this.selectedMskuFnskuId = null
        this.selectedMsku = ''
        this.selectedFnsku = ''

        this.dataGrid.deselectRows(selectedRow)
        data.isSelected = false
        data.merge = false
        store.update(data.id, data)
      } else {
        this.selectedMskuFnskuId = data.id
        this.selectedMsku = data.msku
        this.selectedFnsku = data.fnsku

        // Set Default Previous Values
        const previousSelectedRow = await store.byKey(selectedRow[0])
        previousSelectedRow.isSelected = false
        previousSelectedRow.merge = false
        store.update(selectedRow[0], previousSelectedRow)

        this.dataGrid.deselectRows(selectedRow)
        this.dataGrid.selectRows([data.id], true)
        data.isSelected = true
        data.merge = false
        store.update(data.id, data)
      }
      dataSource.reload()
    },
    async onResetValues() {
      const selectedRow = this.dataGrid.getSelectedRowKeys()
      const dataSource = this.dataGrid.getDataSource()
      const store = dataSource.store()
      if (selectedRow.length > 0) {
        const selectedRowData = await store.byKey(selectedRow[0])
        selectedRowData.isSelected = false
        selectedRowData.merge = false
        store.update(selectedRow[0], selectedRowData)
      }
      this.selectedMskuFnskuId = null
      this.selectedMsku = ''
      this.selectedFnsku = ''
      this.ignoredIds.splice(0, this.ignoredIds.length)
      this.dataGrid.deselectRows(selectedRow)
      dataSource.reload()
    },
    onMergeSelected(e, data) {
      if (e.value) {
        this.ignoredIds.push(data.key)
      } else {
        const exist = find(this.ignoredIds, id => id === data.key)
        if (exist) {
          pull(this.ignoredIds, exist)
        }
      }
    },
    handleSubmitSave() {
      const data = {
        selectedMskuFnskuId: this.selectedMskuFnskuId,
        productId: this.productId,
        companyId: this.companyId,
        selectedMsku: this.selectedMsku,
        selectedFnsku: this.selectedFnsku,
        includeNulls: this.includeNulls,
        ignoredIds: this.ignoredIds,
      }
      inventoryService.mergeMskuFnsku(data).then(() => {
        this.$emit('merge-product-msku-fnksu')
        Notify.success('Products have been merged to selected SKU and FNSKU successfully.')
      })
    },
  },
}
</script>

<style lang="scss">
</style>
