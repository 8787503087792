import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.inventory.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchByQuery(searchFilter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.getByQuery(pageable), searchFilter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchItemsByLocation(searchFilter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.getItemsByLocation(pageable), searchFilter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchItemsByProductId(searchFilter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.getItemsByProduct(pageable), searchFilter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  inventorySnapshot(searchFilter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.inventorySnapshot(pageable), searchFilter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  countItemsByCompanyIdAndStoreAndAction(query) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.inventory.Controller.countItemsByCompanyIdAndStoreAndAction(query))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  fetchItemsByAsinAndParams(query, itemList) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.getItemsByAsinAndParams(query), itemList)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  moveInventory(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.inventory.Controller.moveInventory(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  moveInventoryByLocation(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.inventory.Controller.moveInventoryByLocation(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async createOrAdd(item) {
    return new Promise((resolve, reject) => {
      axios.post(endpoints.inventory.Controller.createOrAdd(), item)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => { reject(error) })
    })
  },

  async decrementItemQuantity(item) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.inventory.Controller.decrementItemQuantity(), item)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateReserveItemsByProductId(item) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.inventory.Controller.updateReserveItemsByProductId(), item)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  update(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.inventory.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoints.inventory.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async mergeMskuFnsku(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.inventory.Controller.mergeMskuFnsku(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => { reject(error) })
    })
  },

  async getByIds(ids) {
    const result = await axios.post(endpoints.inventory.Controller.getByIds(), ids)
    return result.data.body
  },

  async amazonInventoryQuery(data, pageable) {
    const result = await axios.post(endpoints.inventory.Controller.amazonInventoryQuery(pageable), data)
    return result.data.body
  },
}
