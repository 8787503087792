<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="productGrid"
          ref="productGridRef"
          :data-source="productSource"
          :height="setHeight"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @cell-dbl-click="onCellDblClick"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
          @exporting="onExporting"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                v-if="hasPermission"
                ref="btnMerge"
                type="default" icon="copy"
                class="mr-half" hint="Merge Product MSKU/FNSKU"
                @click="onProductMskuFnskuMerge(data)"
              />
              <dx-util-button
                ref="btnEdit"
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit Product"
                @click="onCreateOrUpdate(data)"
              />
              <dx-util-button
                ref="btnDelete"
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <div>
              <small-info-card :product="data.data" @emit-open-product-details="openProductDetails" />
            </div>
          </template>
          <dx-column data-field="upc" caption="UPC" width="auto" cell-template="upcCellTemplate" />
          <template #upcCellTemplate="{data}">
            <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="msku" caption="MSKU" width="auto" />
          <dx-column data-field="fnsku" caption="FNSKU" width="auto" />
          <dx-column data-field="accountNo" caption="Acc #" :visible="hasPermission" width="auto" alignment="left" cell-template="accountCell" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <!-- <dx-column data-field="planId" /> -->
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <robustshell-selectbox
                  v-model="selectedPackType"
                  :enum="$enums.PackTypeEnum"
                  :value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
          <dx-column v-if="hasPermission" data-field="planName" caption="Plan" :width="200" cell-template="planSelectionTemplate" />
          <template #planSelectionTemplate="{ data }">
          <dx-util-button
            :text="data.value ? data.value : 'Select a plan'"
            :hint="data.value ? data.value : 'Select a plan'"
            type="default"
            :styling-mode="data.value ? 'outlined' : 'filled'"
            :element-attr="{ class: 'btn-block' }"
            @click="openPlanSelector(data.data)"
          />
          </template>
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    <!--Begin:: Create Or Update Form-->
        <dx-util-popup
          :visible="popupVisible"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="720"
          :height="640"
          title="Product Info"
        >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
        <form @submit="handleSubmit">
          <dx-util-form ref="productFormRef" :form-data="product" :col-count="1" :show-colon-after-label="true" label-location="top">
            <dx-util-item data-field="title" />
            <dx-util-group-item :col-count="3">
              <dx-util-item data-field="category" editor-type="dxSelectBox" :editor-options="categoryOptions" />
              <dx-util-item data-field="manufacturer" caption="Suplier" editor-type="dxSelectBox" :editor-options="manufacturerOptions" />
              <dx-util-item data-field="isHazmat" editor-type="dxSelectBox" :editor-options="isHazmatOptions" />
            </dx-util-group-item>
            <dx-util-group-item :col-count="2">
              <dx-util-item data-field="upc" />
              <dx-util-item data-field="asin" :editor-options="asinOptions" />
            </dx-util-group-item>
            <dx-util-group-item :col-count="2">
              <dx-util-item data-field="msku" />
              <dx-util-item data-field="fnsku" />
            </dx-util-group-item>
            <dx-util-group-item v-if="hasProductAdmin" :col-count="3">
              <dx-util-item data-field="packType" editor-type="dxSelectBox" :editor-options="packTypes" />
              <dx-util-item data-field="sizeTier" />
              <dx-util-item data-field="dimHeight" editor-type="dxNumberBox" text="Height" />
              <dx-util-item data-field="dimWidth" editor-type="dxNumberBox" text="Width" />
              <dx-util-item data-field="dimLength" editor-type="dxNumberBox" text="Length" />
              <dx-util-item data-field="weight" editor-type="dxNumberBox" text="Weight" />
            </dx-util-group-item>
            <dx-util-item :editor-options="{ height: 80 }" data-field="description" editor-type="dxTextArea" />
          </dx-util-form>
        </form>
      </dx-util-popup>
      <div class="col-md-12 px-0">
        <dx-util-popup
          ref="productMskuFnskuMergePopupRef"
          :visible.sync="isPopupVisible"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          content-template="content"
          title="Merge Product MSKU/FNSKU"
          :width="960"
          :height="640"
          :resize-enabled="true"
          @showing="onShowingMergePopup"
          @hiding="onHidingMergePopup"
        >
          <dx-util-position at="center" my="center" />
          <template #content>
            <product-msku-fnsku-merge
              v-if="isDestroyMergeComp"
              :product-id="parseInt(selectedProductId)"
              :company-id="parseInt(selectedCompanyId)"
              @merge-product-msku-fnksu="onProductMskuFnskuMergeCompleted"
            />
          </template>
        </dx-util-popup>
      </div>
      <div class="col-md-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
    <!--End:: -->
    <!--Begin:: Plan Selector Form-->
    <dx-util-popup
      ref="isPlanSelectorVisibleRef"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="600"
      height="auto"
      title="Select Plan"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-list
        v-model="selectedPlan"
        :data-source="userPlans"
        type="success"
        selection-mode="single"
        @selection-changed="onPlanSelected($event)"
      />
    </dx-util-popup>
    <plan-selector
      :show-plan-selector="isPlanSelectorVisible"
      :plan-id="selectedPlan"
      :product-id="selectedProductId"
      :company-id="parseInt(selectedCompanyId)"
      :account-no="parseInt(selectedAccountNo)"
      @close="onPlanSelectorClosed"
      @planUpdated="loadData(false)"
    />
    <!--End:: Plan Selector Form -->
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isVisible"
      @close="isVisible=false,selectedProductId=null"
    />
    <!--End:: Product Details -->
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import useConfirmation from '@/libs/app/confirmation'
import CoolLightBox from 'vue-cool-lightbox'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import productsService from '@/http/requests/product/productsService'
import manufacturerService from '@/http/requests/product/manufacturerService'
import productCategoryService from '@/http/requests/product/productCategoryService'
import useCurrentUser from '@/libs/app/current-user'
import { productSource } from './productStore'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductMskuFnskuMerge from './components/ProductMskuFnskuMerge.vue'
import SmallInfoCard from './components/SmallInfoCard.vue'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    ProductMskuFnskuMerge,
    'product-details': () => import('./components/ProductDetails.vue'),
    'plan-selector': () => import('@/views/apps/product/products/components/PlanSelector'),
    'small-info-card': SmallInfoCard,
  },
  mixins: [GridBase],
  setup() {
    const { pshConfirm } = useConfirmation()
    const { userWarehouseId } = useCurrentUser()
    return {
      pshConfirm,
      userWarehouseId,
    }
  },
  data: () => ({
    popupVisible: false,
    productSource,
    isHazmatOptions: {
      items: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      valueExpr: 'value',
      displayExpr: 'text',
      value: false,
    },
    packTypes: {
      items: [
        { text: 'Select Pack Taype', value: '' },
        { text: 'Individual', value: 'individual' },
        { text: 'Bundle', value: 'bundle' },
        { text: 'Multi Pack', value: 'multi_pack' },
      ],
      valueExpr: 'value',
      displayExpr: 'text',
      value: '',
    },
    product: {
      id: null,
      companyId: null,
      title: '',
      upc: '',
      asin: '',
      msku: '',
      fnsku: '',
      packType: '',
      category: null,
      manufacturer: null,
      manufacturerName: '',
      dimHeight: null,
      dimLength: null,
      dimWidth: null,
      weight: null,
      sizeTier: 0,
      defaultChargeIds: '',
      defaultChargeCounts: '',
      planId: null,
      isHazmat: false,
      description: '',
    },
    productDefault: {},
    categories: [],
    manufacturers: [],
    accountNo: '',
    accountNumber: '',
    selectedPackType: 'all',
    query: '',
    defaultImg: defaultImageUrl,
    images: [],
    index: null,
    // Merge Product
    selectedProductId: null,
    selectedCompanyId: null,
    isDestroyMergeComp: false,
    isPopupVisible: false,
    userPlans: [],
    selectedPlan: null,
    selectedAccountNo: null,
    isVisible: false,
    isPlanSelectorVisible: false,
    pageIndex: 0,
    pageSize: 25,
  }),
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasProductAdmin() {
      return this.$can('read', 'resource_product_products_admin')
    },
    asinOptions() {
      const isReadonly = !!(this.product.asin)
      return {
        readOnly: isReadonly,
      }
    },
    categoryOptions() {
      return {
        dataSource: this.categories,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
      }
    },
    manufacturerOptions() {
      return {
        dataSource: this.manufacturers,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.product = { ...this.productDefault }
          this.popupVisible = false
        },
      }
    },
    dataGrid() {
      const dataGridIns = this.$refs.productGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
  },
  mounted() {
    this.loadData(true)
    this.getProductCategory()
    this.getManufacturer()
    this.productDefault = { ...this.product }
  },
  methods: {
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          dropDownOptions: {
            width: 240,
          },
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items (max 2000)',
              icon: 'selectall',
            },
            {
              id: 'current',
              name: 'Current Page Items',
              icon: 'export',
            },
          ],
          width: 120,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipment Items',
          onItemClick: arg => {
            switch (arg.itemData.id) {
              case 'current':
                this.onDownloadPageItems()
                break
              default:
                this.includeDetails = false
                this.onDownloadItems()
                break
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.popupVisible = true
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadGrid()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.data.accountNo.toString()
      this.searchByFilter()
    },
    onCreateOrUpdate(e) {
      const id = e.row.data.id
      if (id !== undefined && id !== null && id !== '' && id !== 0) {
        productsService.fetchById(id).then(result => {
          this.product = { ...result.data }
          this.popupVisible = true
        })
      } else {
        this.popupVisible = true
      }
    },
    async onClickDelete(e) {
      const confirm = await this.pshConfirm(
        'Delete Confirmation',
        `<p>Are you sure you want to delete this product record? </p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
      )
      if (confirm) {
        try {
          const result = await productsService.deleteById(e.row.data.id)
          if (result) {
            this.searchByFilter()
          }
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      const store = this.dataSource.store()
      if (this.product.id !== undefined && this.product.id !== null && this.product.id !== '' && this.product.id !== 0) {
        store.update(this.product.id, this.product).then(result => {
          if (result.id !== undefined && result.id !== null && result.id !== '' && result.id !== 0) {
            this.popupVisible = false
            this.product = { ...this.productDefault }
          }
        })
      } else {
        store.insert(this.product).then(() => {
          this.popupVisible = false
          this.product = { ...this.productDefault }
        })
      }
      this.reloadGrid()
    },
    loadData(initial) {
      if (initial) {
        const filters = {
          accountNo: '',
          packType: 'all',
          q: '',
        }
        this.dataSource.searchValue(filters)
        this.dataSource.load()
      } else {
        const filters = {
          accountNo: this.accountNo,
          packType: this.selectedPackType,
          q: this.query,
        }
        this.dataSource.searchValue(filters)
        this.dataSource.reload()
      }
    },
    searchByFilter() {
      const filters = {
        accountNo: this.accountNo,
        packType: this.selectedPackType,
        q: this.query,
      }
      this.dataSource.searchValue(filters)
      this.dataSource.reload()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    getProductCategory() {
      this.categories.splice(0, this.categories.length)
      productCategoryService.fetchAll('?page=0&size=50').then(response => {
        response.data.content.forEach(element => {
          this.categories.push({ id: element.id, name: element.name })
        })
      })
    },
    getManufacturer() {
      this.manufacturers.splice(0, this.manufacturers.length)
      manufacturerService.fetchAll('?page=0&size=50').then(response => {
        response.data.content.forEach(element => {
          this.manufacturers.push({ id: element.id, name: element.name })
        })
      })
    },
    reloadGrid() {
      this.accountNo = ''
      this.selectedPackType = 'all'
      this.query = ''
      this.loadData(false)
    },
    // Merge Product MSKU/FNSKU
    onProductMskuFnskuMerge(e) {
      const popup = this.$refs.productMskuFnskuMergePopupRef.instance
      this.selectedProductId = e.row.key
      this.selectedCompanyId = e.row.data.companyId
      popup.show()
    },
    onShowingMergePopup() {
      this.isDestroyMergeComp = true
    },
    onHidingMergePopup() {
      this.isDestroyMergeComp = false
    },
    onProductMskuFnskuMergeCompleted() {
      const popup = this.$refs.productMskuFnskuMergePopupRef.instance
      popup.hide()
      this.loadData(false)
      this.selectedProductId = null
      this.selectedCompanyId = null
    },
    openPlanSelector(row) {
      this.isPlanSelectorVisible = true
      this.selectedProductId = row.id
      this.selectedCompanyId = row.companyId
      this.selectedAccountNo = row.accountNo
      this.selectedPlan = row.planId
    },
    onPlanSelectorClosed() {
      this.isPlanSelectorVisible = false
      this.selectedProductId = null
      this.selectedCompanyId = null
      this.selectedAccountNo = null
      this.selectedPlan = null
    },
    openProductDetails(productId) {
      this.selectedProductId = productId
      this.isVisible = true
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageIndex') {
        this.pageIndex = e.value
      }
      this.pageSize = this.$refs.productGridRef.instance.pageSize()
    },
    async onDownloadItems() {
      const filters = {
        accountNo: this.accountNo,
        packType: this.selectedPackType,
        q: this.query,
      }
      const pager = new Pager({})
      const max = 2000
      pager.setPageNumber(0, max)
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await productsService.fetchByQuery(filters, pageableQuery)
      const data = response.data.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    async onDownloadPageItems() {
      const filters = {
        accountNo: this.accountNo,
        packType: this.selectedPackType,
        q: this.query,
      }
      const pager = new Pager()
      pager.page = this.pageIndex
      pager.size = this.pageSize
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await productsService.fetchByQuery(filters, pageableQuery)
      const data = response.data.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Products')
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 14 },
        { header: 'Rank', key: 'amzRank', width: 12 },
        { header: 'UPC', key: 'upc', width: 12 },
        { header: 'ASIN', key: 'asin', width: 12 },
        { header: 'MSKU', key: 'msku', width: 12 },
        { header: 'FNSKU', key: 'fnsku', width: 12 },
        { header: 'Hazmat', key: 'isHazmat', width: 12 },
        { header: 'Pack Type', key: 'packType', width: 12 },
        { header: 'Length', key: 'length', width: 12 },
        { header: 'Height', key: 'height', width: 12 },
        { header: 'Width', key: 'width', width: 12 },
        { header: 'Size Tier', key: 'sizeTier', width: 12 },
        { header: 'Pack Type', key: 'packType', width: 12 },
        { header: 'Manufacturer', key: 'manufacturer', width: 12 },
      ]

      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.amzRank,
          item.upc,
          item.asin,
          item.msku,
          item.fnsku,
          item.isHazmat,
          item.packType,
          item.length,
          item.height,
          item.width,
          item.sizeTier,
          item.packType,
          item.manufacturer,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = Date.now()
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Products-${fileName}.xlsx`,
        )
      })
    },
  },
}
</script>

<style lang="scss">
td[role=columnheader] {
    text-align: center!important
}

.text-caption {
    font-size: 10px;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
