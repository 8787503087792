<template>
  <div class="dx-product-title" @click="openProductDetails">
    <strong style="font-size: 15px;" class="text-info" role="button">
      {{ product.title }}
    </strong>
    <div class="d-flex justify-content-start mt-half align-items-end row">
      <div class="col-3 px-half ml-1">
        <span class="text-muted">
          Supplier: {{ product.manufacturer || '-' }}
        </span>
      </div>
      <div class="col-3 px-half ml-1">
        <span class="text-muted">
          Product Category: {{ product.productType || '-' }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-start mt-half align-items-end row">
      <div class="col-3 px-half ml-1">
        Dimensions: <span class="badge badge-light-success mr-4">{{ product.dimLength }} in. x {{ product.dimWidth }} in. x {{ product.dimHeight }} in.</span>
      </div>
      <div class="col-2 px-half">
        Weight: <span class="badge badge-light-success mr-4">{{ product.weight }} lb.</span>
      </div>
      <!-- <div class="col-2 px-half">
        <span class="badge badge-light-primary text-uppercase mr-4">
          {{ product.packType }}
        </span>
      </div> -->
      <!-- <div class="col-2 px-half">
        Hazmat: <p-icon name="psh-flame" :color="product.isHazmat ? 'danger': 'gray'" :size="product.isHazmat ? '28px': '24px'" class="mr-4" />
      </div> -->
      <div class="col-2 px-half">
        Size Tier: <span v-if="product.sizeTier !== null" class="badge" :class="`badge-light-${resolveStatusVariant(product.sizeTier)}`">{{ product.sizeTier }}</span>
      </div>
      <div class="col-2 px-half">
        Rank: <span v-if="product.amzRank" class="badge badge-light-success">{{ product.amzRank }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openProductDetails() {
      this.$emit('emit-open-product-details', this.product.id)
    },
    resolvePackType(val) {
      if (val === 'individual') return 'badge-light-primary'
      if (val === 'bundle') return 'badge-light-warning'
      return ''
    },
    resolveStatusVariant(tier) {
      if (tier < 2) return 'secondary'
      return 'warning'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
