import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all product category
  async fetchAll(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.productCategory.Controller.findAll(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get product categories mathing name
  async fetchAllMatchingName(name) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.productCategory.Controller.findByName(name))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // create product category
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.productCategory.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update product category
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.productCategory.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete product category
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.productCategory.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
