/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import productsService from '@/http/requests/product/productsService'
import Pager from '@core/dev-extreme/utils/pager'

const isNotEmpty = value => value !== undefined && value !== null && value !== ''

const productStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)
    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await productsService.fetchByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  insert: values => {
    productsService.create(values)
  },
  update: (key, values) => {
    const data = {
      id: key,
      ...values,
    }
    productsService.update(data)
  },
  remove: key => {
    productsService.deleteById(key)
  },
})

const productSource = new DataSource({
  store: productStore,
})

export {
  productSource,
}
